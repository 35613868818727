@import "../../mainVariables";

.service-header {
  width: 100%;
  margin: 80px 0 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .service-header-info {
    width: 48%;
    padding-top: 82px;
    .service-header-info-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: $brandColor;
      margin-bottom: 16px;
    }
    .service-header-info-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      color: #101828;
      margin-bottom: 24px;
    }
    .service-header-info-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 48px;
    }
  }
  .service-header-img{
    width: 48%;
    .header-img{
      position: relative;
      padding-top: 84.5%;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .service-header {
    background-image: none !important;
    padding: $mainPadding;
    margin-top: 60px;
    margin-bottom: 80px;
    .service-header-info {
      width: 100%;
      margin-bottom: 30px;
      .service-header-info-title {
        font-size: 40px;
        line-height: 50px;
        overflow: hidden;
      }
    }
    .service-header-img{
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .service-header {
    margin-top: 40px;
    .service-header-info {
      margin: 0;
      .service-header-info-title {
        font-size: 36px;
        line-height: 46px;
        overflow: hidden;
      }
      .service-header-info-description {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
