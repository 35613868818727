@import "../mainVariables";
@import "../mixins";


.mainBlock{
  width: 100%;
  overflow: hidden;
  background-color:#F6F9FF;
  position: relative;
  padding: 0  $mainPadding;
  height: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-block{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .img-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .info-block{
    width:1140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    .text{
      width: 50%;
      .title{
        font-size: 60px;
        font-weight: 700;
        line-height: 78px;
        letter-spacing: 0;
        text-align: left;
        color: #101828;
      }
      .description{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 24px;
        color: #475467;
      }
      .apply-btn{
        width: 198px;
        height: 54px;
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $brandColor;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .info-block{
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px){
    background-color:#F6F9FF;
    padding: 60px $mainPadding;
    height: unset;
    .img-block{
      display: none;
    }
    .info-block{
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
      .text{
        width: 80%;
        .apply-btn{
          width: 100%;
        }
      }
      .contact-block{
        display: none;
      }
      .consultation{
        cursor: pointer;
        display:flex;
        background-color:$brandColor;
        width: 40%;
        align-self: center;
        justify-content: center;
        padding: 12px 0;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        color: white;
        margin-top: 44px;
      }
    }
  }
  @media screen and (max-width: 700px){
    .info-block{
      .text{
        width: 100%;
        .title{
          font-size: 36px;
          font-weight: 600;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .description{
          width: 100%;
          font-size: 18px;
        }
      }
      .consultation{
        width: 96%;
      }
    }
  }
}