@import "../../mainVariables";

.service-advantage {
  margin-bottom: 80px;
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #101828;
    margin-bottom: 20px;
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-bottom: 40px;
    width: 85%;
  }
  .service-advantage-items {
    display: flex;
    flex-wrap: wrap; 
  }
}

@media screen and (max-width: 1050px) {
  .service-advantage {
    margin-right: 0;
    h2{
      font-size: 32px;
      line-height: 40px;
    }
    h4{
      font-size: 18px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 600px) {
  .service-advantage {
    h2{
      font-size: 26px;
      line-height: 36px;
    }
    h4{
      font-size: 16px;
      line-height: 24px;
    }
  }
}
