@import "../mainVariables";
@import "../mixins";


.maintenance-block{
  width: 100%;
  position: relative;
  background-color: #FCFDFF;
  display: flex;
  align-items: center;
  justify-content: center;
  .maintenance-wrapper{
    position: relative;
    width: 1140px;
    //padding: 0 0 0 $mainPadding;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 96px;
    .maintenance-info{
      width: 48%;
      .maintenance-link{
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #3AA9C8;
          margin-right: 6px;
        }
      }
      .maintenance-title{
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #101828;
        margin-top: 16px;
      }
      .maintenance-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin-top: 20px;
      }
    }
    .maintenance-img{
      width: 48%;
      position: relative;
      .maintenanceBg{
        width: 110%;
        position: absolute;
        right: 0;
        top: -56px;
        bottom: -56px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .responsive-img{
       display: none;
      }
      .img-wrapper{
        position: relative;
        padding-top: 86%;
        z-index: 1000;
        margin: 38px 0 ;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          padding-right: 160px;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 100%;
    padding: 0 0 0 $mainPadding;
  }

  @media screen and (max-width: 1050px){
    .maintenance-wrapper{
      background-color: white;
      flex-direction: column;
      padding: 40px $mainPadding 0;
      margin-bottom: unset;
      .maintenance-info{
        width: 100%;
        .maintenance-link{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
        }
        .maintenance-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .maintenance-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      .maintenance-img{
        width: 100%;
        margin-top: 44px;
        .maintenanceBg{
          display: none;
        }
        .img-wrapper{
          display: none;
        }
        .responsive-img{
          display: flex;
          padding-top: 71.7%;
          position: relative;
          img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}