@import "../../mainVariables";


.linear-loading {
  .loading-icon{
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;

    svg{
      width: 50px;
      height: 50px;
      animation-name: rotate;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      border-radius: 50%;
      path{
        fill:$brandColor;
      }
    }
  }
}
@keyframes rotate{
  from{ transform: rotate(-360deg); }
  to{ transform: rotate(360deg); }
}