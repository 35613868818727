@import "../../mainVariables";

.service-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0 0;
  .service-slider-header {
    width: 740px;
    .service-slider-name {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #101828;
      margin-bottom: 100px;
    }
  }
  .service-slider-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .service-slider-info {
      width: 48%;
        .service-slider-info-description {
         width: 100%;
          p{
            span{
              text-wrap: unset!important;
            }
          }
        }
    }
    .service-slider-image {
      width: 48%;
      .service-slider-image-relative {
        position: relative;
        padding-top: 80%;
        z-index: 1000;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}


@media screen and (max-width: 1050px) {
  .service-slider {
    padding-block: 60px;
    .service-slider-header {
      width: 100%;
      .service-slider-name {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 32px;
      }
    }
    .service-slider-main {
      flex-direction: column-reverse;
      .service-slider-info {
        width: 100%;
        .service-slider-info-title {
          font-size: 22px;
          line-height: 30px;
        }
        .service-slider-info-description {
          font-size: 18px;
          line-height: 26px;
        }
      }
      .service-slider-image {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .service-slider {
    padding-block: 28px;
    .service-slider-name {
      font-size: 26px;
      line-height: 38px;
    }
    .service-slider-info-title {
      font-size: 20px;
      line-height: 28px;
    }
    .service-slider-info-description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
