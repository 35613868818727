@import "../mainVariables";
@import "../mixins";

.color{
  background-color:white;
}
.fixe{
  position: fixed!important;
  width: 100%;
  background-color: white;
}
.header-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  border-bottom: 1px solid #EAECF0;
  padding: 0 $mainPadding;
  position: relative;
  top: 0;
  z-index: 1002;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .header-block{
    width: 1140px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bg-block{
      width: 52%;
      position: absolute;
      right: 0;
      background-color: white;
      border-top-left-radius: 100%;
      overflow: hidden;
      height: 235px;
    }

    a{
      .ask-logo{
        img{
          width: 150px;
          height: 100px;
        }
        svg{
          width: 150px;
          height: 100px;
        }
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      padding-right: 44px;
      z-index: 1000;
      .active{
        .nav-item{
          color:$brandColor;
        }
      }

      .telematic-block{
        border: 1px solid #475467;
        width: 198px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 60px;
        .telematic-text{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #101828;
        }
      }
      .nav-item {
        margin-left: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        cursor: pointer;
        color: #475467;

        &:hover {
          color: $brandColor;
          svg{
            path{
              fill:$brandColor;
            }
          }
        }

        .selected-language{
          background: #EFF2F4;
          padding: 12px;
          border-radius: 4px;
        }
      }

      .header_burger {
        display: none;
        position: absolute;
        top: 46%;
        transform: translateY(-50%);
        right: 23px;
        width: 28px;
        height: 28px;
        cursor: pointer;
        svg{
          width: 100%;
          height: 100%;
        }
      }
    }
    div{
      position: relative !important;
    }
  }

  @media all and (max-width: 1300px) {
    .header-block{
      .nav-menu {

        .nav-item {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    background-color: #F5F5F5;
    border-bottom: unset;
    .header-block{
      width: 100%;
      .bg-block{
        display: none!important;
      }
      .header_burger{
        display: flex!important;
      }
      .nav-menu {

        .nav-item {
          display: none;
        }
        .telematic-block{
          display: none;
        }
      }
    }
  }
}