@import "../mainVariables";
@import "../mixins";

.news-modal-wrapper{
  width: 100%;
  .news-block-img{
    padding-top: 49.6%;
    position: relative;
    margin-top: 20px;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  .news-block-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    .news-date{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #3AA9C8;
    }
    .news-block-title{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      text-align: center;
      color: #101828;
      margin-top: 12px;
    }
    .news-block-description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #475467;
      margin-top: 8px;
    }
  }
}