@import "../mainVariables";
@import "../mixins";

.service_page-wrapper{
  -webkit-user-select:none!important;
  user-select: none!important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 $mainPadding;
  .service_page_blocks{
    width: 1140px;
  }

  .loading-wrapper{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1250px){
    .service_page_blocks{
      width: 100%;
    }
  }
}