@import "../mainVariables";
@import "../mixins";

.homepage-news-wrapper{
  width: 100%;
  background-color: #FCFDFF;
  padding: 96px $mainPadding 92px $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  .homepage-news-block{
    width: 1140px;
    .header-news-block{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .news-text{
        width: 74%;
        .homepage-news-title{
          font-size: 34px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #101828;
        }
        .homepage-news-description{
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
          margin-top: 20px;
        }
      }
      .news-btn{
        width: 220px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #667085;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        cursor: pointer;
      }
    }
    .news-wrapper{
      margin-top: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .news{
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .news-block{
          &:hover{
            .news-block-info{
              .news-title-wrapper{
                .news-block-title{
                  color:$brandColor;
                }
                .news-block-link{
                  svg{
                    path{
                      stroke:$brandColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .news-btn{
        display: none;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .homepage-news-block{
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px){
    padding: 40px $mainPadding;
    .homepage-news-block{
      .header-news-block{
        .news-text{
          width: 100%;
          .homepage-news-title{
            font-size: 26px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #101828;
          }
          .homepage-news-description{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #475467;
          }
        }
        .news-btn{
          display: none;
        }
      }
      .news-wrapper{
        margin-top: 20px;
        flex-direction: column;
        .news{
          flex-direction: column;
        }
        .news-btn{
          width: 100%;
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #667085;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
          cursor: pointer;
          margin-top: 52px;
        }
      }
    }
  }
}