@import "../mainVariables";
@import "../mixins";

.description-wrapper{
  width:1140px;
  position: relative;
  background-color: white;
  .description-content{
    width: 100%;
    padding-top: 90px;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: space-between;
    .text-content{
      width: 58%;
      p{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #3AA9C8;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg{
          margin-left: 6px;
          margin-top: 3px;
        }
      }
      .title{
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #101828;
        margin-bottom: 20px;
      }
      .text{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
    }
  }
  .projects-block{
    margin-top: 134px;
    padding-bottom: 80px;
    .project{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .project-title{
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        margin: 16px 0;
        color: black;
      }
      .project-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        word-break: break-all;
        margin-bottom: 20px;
      }
      .project-more-news{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color:#4062C8;
        cursor: pointer;
        svg{
          margin-left: 10px;
          path{
            stroke:#4062C8;
          }
        }
      }
    }
    .img-wrapper{
      width: 100%;
      height: 100%;
      //position: relative;
      //display: flex;
      //justify-content: flex-end;
      //right: -24px;

      .img{
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 999;
        display: contents;
        object-fit: cover;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media screen and (max-width:1200px){
    width: 100%;
    padding: 0 0 0 $mainPadding;
    .description-content{
      width: 100%;
      .text-content{
        width: 100%;
      }
      .bg-wrapper{
        display: none;
      }
    }
    .projects-block{
      .img-wrapper{
        display: none;
      }
    }
  }
}