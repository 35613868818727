@import "../mainVariables";
@import "../mixins";

.contact-wrapper{
  -webkit-user-select:none!important;
  user-select: none!important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .top-block{
    width: 100%;
    padding: 60px 0 60px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info-block{
      width: 520px;
      .info-title{
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #101828;
      }
      .blocks-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #3F64CA;
        display: flex;
        align-items: center;
        svg{
          margin-right: 18px;
        }
      }
      .information{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin: 8px 0 0 42px;
      }
      .email-block{
        margin-top: 52px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
      .number-block{
        margin-top: 12px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
      .address-block{
        margin-top: 12px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
    }
    .contact-map-block{
      width: 54%;
      height: 720px;
      .yandexMap{
        width: 100%;
        height: 100%;
        .ymaps-2-1-79-map{
          width: 100%!important;
          height: 100%!important;

        }
      }
    }
  }
  .bottom-block{
    width: 1140px;
    margin: 80px 0 40px 0;
    .inputs-subTitle{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #3AA9C8;
    }
    .inputs-title{
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #101828;
      margin-top: 16px;
    }
    .inputs-description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: left;
      color: #475467;
      margin-top: 20px;
    }
    .name-inputs{
      margin-top: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .custom-input-group{
        width: 49%;
        label{
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
          margin-bottom: 8px;
        }
        input{
          height: 48px;
          padding: 12px 16px;
          border: 1px solid #909AAB;
        }
      }
    }
    .custom-input-group{
      width: 100%;
      label{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
      input{
        height: 48px;
        margin-top: 8px;
        padding: 12px 16px;
        border: 1px solid #909AAB;
      }
      textarea{
        height: 130px;
        margin-top: 8px;
        padding: 12px 16px;
        border: 1px solid #909AAB;
      }
    }
    .sent-email{
      width: 188px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color: white;
      background-color: #3AA9C8;
    }
  }

  @media screen and (max-width: 1050px){
    .top-block{
      padding: 60px $mainPadding;
      .info-block{
        width: 100%;
        .info-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.01em;
          text-align: center;
        }
      }
      .contact-map-block{
        display: none;
      }
    }
    .bottom-block{
      width: 100%;
      padding: 40px $mainPadding 0;
      .inputs-subTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }
      .inputs-title{
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.01em;
        text-align: left;
      }
      .inputs-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
      }
      .name-inputs{
        flex-direction: column;
        .custom-input-group{
          width: 100%;
        }
      }
      .sent-email{
        width: 100%;
      }
    }
  }
}
