@import "../../../assets/styles/mainVariables";
.linkButton{
    width: 198px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brandColor;
    border-radius: 2px;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: white;
    cursor: pointer;
}
.linkButton:hover{
    color: white;
}

@media screen and (max-width: 600px) {
    .linkButton{
        width: 100%;
    }
}