@import "../../mainVariables";

.service-possibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background: #FCFDFF;
  .service-possibility-main {
    text-align: center;
    .service-possibility-main-title {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #101828;
      margin-bottom: 24px;
    }
    .service-possibility-main-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 92px;
    }
  }
  .service-block{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    .service-possibility-image-block{
      width: calc(90% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 58px;
        height: 58px;
        object-fit: cover;
      }
      .service-possibility-image-block-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #231f20;
        margin-block: 6px;
      }
      .service-possibility-image-block-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
      }
    }
  }
  .service-possibility-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    .service-possibility-image-block {
      width:calc(90% / 4);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 58px;
        height: 58px;
        object-fit: cover;
      }
      .service-possibility-image-block-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #231f20;
        margin-block: 6px;
      }
      .service-possibility-image-block-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .service-possibility {
    padding: 0 $mainPadding;
    margin-bottom: 56px;
    align-items: center;
    .service-possibility-main {
      .service-possibility-main-title {
        font-size: 26px;
        line-height: 38px;
      }
      .service-possibility-main-description {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .service-block{
      width: 100%;
      align-items: center;
      flex-direction: column;
      .service-possibility-image-block{
        width: 100%;
      }
    }
    .service-possibility-images {
      align-items: center;
      flex-direction: column;
      .service-possibility-image-block{
        width: 100%;
      }
    }
  }
}
