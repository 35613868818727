@import "../fonts";
@import "../mainVariables";

.sponsors-wrapper {
  width: 1140px;
  padding: 104px 0 120px ;
  background-color: white;
  margin-bottom: 70px;

  .more-info{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    span{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: #3AA9C8;
    }
    svg{
      margin-left: 6px;
      margin-top: 2px;
    }
  }
  .sponsors-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    margin-bottom: 45px;
    text-align: center;
    color: #101828;
  }
  .sponsors-list-wrapper {
    position: relative;

    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: auto;
          padding-top: 5px;
          @media screen and (max-width: 400px){
            margin-right: 90px;
          }
        }
      }
    }
    .slick-slide .slick-active{
      width: 300px;
    }

    .sponsors-item {
      //width: 90%;
      //height: 100%;
      //position: relative;
      width: 100%;
      height: 100%;
      margin-right: 20px;

      .sponsors-content {
        width: 100%;
        height: 100%;
        padding-top: 39%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          //height: 100%;
          //margin: auto;
          //transition: all 0.3s;
          padding: 0 10px;
          position: absolute;
          top: 0;
          left: 0;
        }
        //@media screen and (max-width:400px){
        //  padding-top:40% ;
        //}
      }
    }
    .prev-btn , .next-btn{
      top: unset;
      transform: unset;
      margin-top: 40px;
      border: 1px solid #EAECF0;
      box-shadow: unset;
    }
    .next-btn{
      right: unset;
      left: 60px;
    }
  }

  @media screen and (max-width: 1250px){
    width: 100%;
  }

  @media all and (max-width: 1050px) {
    padding: 40px $mainPadding 60px;
    margin-bottom: unset;
    .sponsors-title {
      margin: 16px 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
    }
    .sponsors-responsive-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .sponsors-item {
        width: 100%;
        height: 100%;
        margin-top: 44px;

        .sponsors-content {
          width: 100%;
          height: 100%;
          padding-top: 39%;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          //@media screen and (max-width:400px){
          //  padding-top:24% ;
          //}
        }
      }
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }
}