.advantage-item {
  display: flex;
  align-items: center;
  width: 50%;
  min-height: 60px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
  .advantage-item-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
  }
}

@media screen and (max-width: 1050px){
    .advantage-item {
        width: 100%;
    }
}
