@import "../../mainVariables";

.service-information {
  margin-bottom: 203px;
  display: flex;
  flex-direction: column;
  .service-information-main {
    .text{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: $brandColor;
      margin-bottom: 16px;
    }
    .service-information-main-title {
      width: 70%;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #101828;
      margin-bottom: 20px;
    }
    .service-information-main-description {
      width: 85%;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 65px;
    }
  }

  .service-information-images {
    display: flex;
    justify-content: space-between;
    position: relative;
    .service-information-image-block {
      width: 25%;
      min-height: 216px;
      background: #f6f9ff;
      border: 0.5px solid #bdcceb;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
      }
      .service-information-image-block-text {
        text-align: center;
        width: 80%;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #475467;
        margin-top: 12px;
      }
    }
    .vector1-block {
      position: absolute;
      top: -30%;
      left: 55%;
      .vector1-block-img {
        position: relative;
        .vector1 {
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .vector1-arrow {
          position: absolute;
          top: 50px;
          left: 385px;
        }
      }
    }
    .vector2-block {
      position: absolute;
      top: 100%;
      left: 20%;
      .vector2-block-img {
        position: relative;
        .vector2 {
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .vector2-arrow {
          position: absolute;
          top: -25px;
          left: 345px;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .service-information {
    .service-information-image-block{
      width: 100% !important;
    }
    .service-information-main {
      .service-information-main-title {
        width: 90%;
        font-size: 32px;
        line-height: 40px;
      }
      .service-information-main-description {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .service-information-images {
    flex-direction: column;
    align-items: center;
    .service-information-image-block{
      margin-bottom: 22px;
    }
    .vector1-block {
      display: none;
    }
    .vector2-block {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .service-information {
    margin-bottom: 72px;
    align-items: center;
    .service-information-main {
      .service-information-main-title {
        font-size: 26px;
        line-height: 38px;
      }
      .service-information-main-description {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .service-information-images {
      .service-information-image-block{
        width: 100% !important;
        margin-bottom: 12px;
      }
    }
  }
}
